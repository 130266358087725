import { ItemConditionEnum, itemInterface } from '../repositories/items';
import { trackingNumberInterface } from '../repositories/trackingNumbers';
import { tagInterface } from '../repositories/tags';
import { imageInterface } from '../repositories/images';
import { companyServiceWithPivotInterface } from '../repositories/companyServices';
import { Attachment } from '../attachments';
import { warehouseInterface } from '../warehouses';
import { Move, WithArrivableLocation } from '@/api/moves';
import { PaginatedRequestFunction, PaginatedResults, requestFilterInterface } from '@/compiler/types';
import axios from '@/helpers/forms/axios';

export interface InboundShipment {
    id: number
    createdAt: string
    updatedAt: string
    name: string
    teamId: number
    notes: string
    internalNotes: string
    companyServices: companyServiceWithPivotInterface[]
    attachments?: Attachment[]
    warehouseId: number
    warehouse?: warehouseInterface
    status: InboundShipmentStatus
    receivedAt: string
    referenceId: string
    trackingNumbers: trackingNumberInterface[]
    tags: tagInterface[]
    searchableTags: string[]
    shippedItemsCount: number
    actualQuantity: number
    expectedQuantity: number
    unsellableQuantity: number
}

export type InboundShipmentStatus = 'open' | 'shipped' | 'received';

export interface ShipmentItemGroupConfiguration {
    configurationId: number
    quantity: number
    partialQuantity: number
}

export interface InboundShipmentItem {
    id: number
    item: itemInterface
    itemId: number
    shipmentId: number
    expected: {
        quantity: number
        itemGroupConfigurations: ShipmentItemGroupConfiguration[]
    }
    actual: {
        quantity: number
        itemGroupConfigurations: ShipmentItemGroupConfiguration[]
        locationMoves: Array<Move & WithArrivableLocation>
    }
    unsellable: {
        quantity: number
    }
}

export interface InboundShipmentLiveTrackingInfo {
    status: 'pending' | 'transit' | 'delivered' | 'returned' | 'failure' | 'unknown'
    number: number | null
    eta: string | null
}

export interface UnsellableItem {
    id: number
    itemId: number
    images: imageInterface[]
    notes: string
    receivedCondition?: ItemConditionEnum
    quantity: number
    item: itemInterface
    shipmentId: number
    shipment: InboundShipment
}

const fetchInboundShipments: PaginatedRequestFunction<InboundShipment> = async (page: number, filters?: requestFilterInterface[]) => {
    const url = new URL('/api/shipments/inbound', location.origin);

    url.searchParams.append('page', page.toString());
    if (filters) {
        filters.forEach((filter) => {
            url.searchParams.append(`filters[${filter.name}]`, filter.value);
        });
    }

    const response = await axios.get<PaginatedResults<InboundShipment>>(url.toString());

    return response.data;
};

const fetchInboundShipment = async (shipmentId: number): Promise<InboundShipment> => {
    const response = await axios.get<{ shipment: InboundShipment }>(`/api/shipments/inbound/${shipmentId}`);

    return response.data.shipment;
};

const createInboundShipment = async (name: string, warehouseId: number): Promise<{ shipmentId: number }> => {
    const response = await axios.post<{ shipmentId: number }>('/api/shipments/inbound', {
        name,
        warehouseId,
    });

    return response.data;
};

const fetchInboundShipmentLiveTracking = async (shipmentId: number): Promise<InboundShipmentLiveTrackingInfo[]> => {
    const response = await axios.get<{ tracking: InboundShipmentLiveTrackingInfo[] }>(`/api/shipments/inbound/${shipmentId}/track`);

    return response.data.tracking;
};

const removeTagFromInboundShipment = async (shipmentId: number, tagId: number): Promise<void> => {
    return await axios.delete(`/api/shipments/inbound/${shipmentId}/tags/delete-tag/${tagId}`);
};

const addTagToInboundShipment = async (shipmentId: number, tagId: number): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${shipmentId}/tags/add-tag/${tagId}`);
};

const loadArchivedShipments: PaginatedRequestFunction<InboundShipment> = async (page: number) => {
    const response = await axios.get<PaginatedResults<InboundShipment>>(`/api/shipments/inbound/archived?page=${page}`);

    return response.data;
};

const batchArchive = async (shipmentIds: number[]): Promise<void> => {
    return await axios.post('/api/shipments/inbound/batch/archive', {
        shipmentIds,
    });
};

const batchUnarchive = async (shipmentIds: number[]): Promise<void> => {
    return await axios.post('/api/shipments/inbound/batch/unarchive', {
        shipmentIds,
    });
};

// Items
const fetchInboundShipmentItems = async (shipmentId: number): Promise<InboundShipmentItem[]> => {
    const response = await axios.get<{ items: InboundShipmentItem[] }>(`/api/shipments/inbound/${shipmentId}/items`);

    return response.data.items;
};

const fetchUnsellableItems = async (shipmentId: number, itemId: number | null): Promise<UnsellableItem[]> => {
    const url = new URL(`/api/shipments/inbound/${shipmentId}/inbound-shipment-unsellable-item`, location.origin);

    if (itemId) {
        url.searchParams.append('item_id', itemId.toString());
    }

    const response = await axios.get<UnsellableItem[]>(url.toString());

    return response.data;
};

const addInboundShipmentItem = async (shipmentId: number, itemId: number, quantity: number): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${shipmentId}/add-item`, { itemId, quantity });
};

const updateInboundShipmentItem = async (item: InboundShipmentItem): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${item.shipmentId}/update-item`, item);
};

const deleteInboundShipmentItem = async (shipmentId: number, itemId: number): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${shipmentId}/remove-item`, { itemId });
};

const addTrackingNumbers = async (shipmentId: number, carrier: string, trackingNumbers: string[]): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${shipmentId}/add-tracking`, { carrier, trackingNumbers });
};

const deleteUnsellableItem = async (unsellableItemId: number): Promise<void> => {
    return axios.delete(`/api/shipments/inbound/inbound-shipment-unsellable-item/${unsellableItemId}`);
};

const receiveInboundShipment = async (shipmentId: number): Promise<void> => {
    return await axios.post(`/api/shipments/inbound/${shipmentId}/receive`);
};

export default {
    addTrackingNumbers,
    batchArchive,
    batchUnarchive,
    loadArchivedShipments,
    fetchInboundShipments,
    fetchInboundShipment,
    createInboundShipment,
    fetchInboundShipmentLiveTracking,
    removeTagFromInboundShipment,
    addTagToInboundShipment,

    fetchInboundShipmentItems,
    addInboundShipmentItem,
    updateInboundShipmentItem,
    deleteInboundShipmentItem,
    fetchUnsellableItems,
    deleteUnsellableItem,

    receiveInboundShipment,
};
