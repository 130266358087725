import { defineStore } from 'pinia';
import { DocumentArrowDownIcon } from '@heroicons/vue/24/outline';
import { ActionableOnSuccessOperation, Operation, OperationProblem, OperationStatus, useOperationsStore } from './operations';
import API from '@/api';
import { ExportOperation } from '@/api/reports/exports';
import { DataFetcherFunction, DataFetcherResponse, DataFetcherSort } from '@/compiler/types';
import usePopupNotifications from '@/composables/usePopupNotifications';
import { useNarrative } from '@/composables/useNarrative';

interface State {
    operations: ExportOperation[]
    loadOperationsPromise: Promise<void> | null
    loadingOperationIds: string[]
}

export const useExportOperationsStore = defineStore('exportOperations', {
    state: (): State => ({
        operations: [],
        loadOperationsPromise: null,
        loadingOperationIds: [],
    }),
    getters: {
        getOperationById: state => (operationId: string) => {
            return state.operations?.filter(operation => operation.id === operationId)[0] ?? null;
        },
    },
    actions: {
        async loadOperations(): Promise<void> {
            if (this.loadOperationsPromise) return this.loadOperationsPromise;

            this.loadOperationsPromise = this.refreshOperations().finally(() => {
                this.loadOperationsPromise = null;
            });

            return this.loadOperationsPromise;
        },
        async loadOperationsById(operationIds: string[]): Promise<void> {
            // get all operations that are not in the list or being loaded
            const missingOperations = operationIds
                .filter(id => !this.operations.some(operation => operation.id === id))
                .filter(id => !this.loadingOperationIds.includes(id));

            if (!missingOperations.length) return;

            this.loadingOperationIds.push(...missingOperations);

            const filters = missingOperations.map(id => `id:"${id}"`).join(' OR ');
            const response = await API.reports.exports.getOperations(1, 100, [], filters);

            response.data.forEach(operation => this.set(operation));
        },
        async refreshOperations(): Promise<void> {
            const response = await API.reports.exports.getOperations(1, 100, [], '');

            response.data.forEach(operation => this.set(operation));
        },
        async fetchOperation(operationId: string): Promise<ExportOperation> {
            const response = await API.reports.exports.fetchOperation(operationId);

            this.set(response);

            return response;
        },
        getOperationSource(): DataFetcherFunction<Operation> {
            return async (page: number, pageSize: number, sorts: DataFetcherSort[], filters: string): Promise<DataFetcherResponse<Operation>> => {
                const response = (await API.reports.exports.getOperations(page, pageSize, sorts, filters));

                return {
                    ...response,
                    data: response.data.map(operation => this.getBaseOperation(operation)),
                };
            };
        },
        getBaseOperation(operation: ExportOperation): BaseExportOperation {
            return new BaseExportOperation(operation);
        },
        set(operation: ExportOperation): void {
            const operationIndex = this.operations.findIndex(findOperation => findOperation.id === operation.id);

            if (operationIndex >= 0) {
                this.operations[operationIndex] = operation;
            } else {
                this.operations.push(operation);
            }

            useOperationsStore().set(this.getBaseOperation(operation));
        },
    },
});

class BaseExportOperation implements ActionableOnSuccessOperation {
    constructor(
        private operation: ExportOperation,
    ) {
    }

    getId(): string {
        return this.operation.id;
    }

    getCreatedAt(): string {
        return this.operation.createdAt;
    }

    getStatus(): OperationStatus {
        if (this.operation.failedAt) return OperationStatus.Failed;
        if (this.operation.progress === 100) return OperationStatus.Succeeded;

        return OperationStatus.InProgress;
    }

    getProgress(): number {
        return this.operation.progress;
    }

    getDescription(): string {
        return `Export: ${this.operation.description}`;
    }

    getWarnings(): OperationProblem[] {
        return [];
    }

    getErrors(): OperationProblem[] {
        if (!this.operation.failureMessage) return [];

        return [{
            code: 'export-failure',
            message: this.operation.failureMessage,
        }];
    }

    updateIntervalSeconds(): number {
        return 10;
    }

    async fetchUpdate(): Promise<void> {
        return useExportOperationsStore().fetchOperation(this.operation.id).then((response) => {
            this.operation = response;

            if (response.progress === 100 && useNarrative().currentUserOptional.value?.id === this.operation.userId) {
                usePopupNotifications().notifyAction(`Export complete: ${response.description}`, 'Download', () => this.executeAction());
            }
        });
    }

    executeAction(): Promise<void> {
        return API.reports.exports.getDownloadUrlForOperation(this.operation.id).then((response) => {
            window.open(response, '_blank');
        });
    }

    actionText(): string {
        return 'Download';
    }

    actionIcon(): Function {
        return DocumentArrowDownIcon;
    }
}
