<template>
    <base-card
        v-if="!loadingServiceProviders || !serviceProviders.length"
        class="min-w-full"
    >
        <div class="flow-root my-6">
            <ul
                role="list"
                class="-my-5 divide-y divide-gray-200"
            >
                <li
                    v-for="invitation in invitations"
                    :key="invitation.id"
                    class="py-4"
                >
                    <div class="flex items-center space-x-4">
                        <div
                            v-if="invitation.serviceProvider.faviconFile"
                            class="shrink-0"
                        >
                            <img
                                class="h-8 w-8 rounded-full"
                                :src="invitation.serviceProvider.faviconFile"
                                :alt="invitation.serviceProvider.name"
                            >
                        </div>
                        <div class="flex-1 min-w-0">
                            <base-badge
                                size="sm"
                                :colour="BaseColour.Yellow"
                            >
                                Pending Invitation
                            </base-badge>

                            <p class="text-sm font-medium text-gray-900 truncate">
                                {{ invitation.serviceProvider.name }}
                            </p>
                            <p class="text-sm text-gray-500 truncate">
                                <span>{{ slugToTitleCase(invitation.role) }}</span>
                            </p>
                        </div>
                        <div class="flex flex-col space-y-2">
                            <base-button
                                :icon="CheckCircleIcon"
                                :colour="BaseColour.Green"
                                size="xs"
                                text="Accept"
                                design="outline"
                                :click-func="() => acceptInvitation(invitation)"
                                :busy="respondingToInvitation"
                            />
                            <base-button
                                :icon="CheckCircleIcon"
                                :colour="BaseColour.Red"
                                size="xs"
                                text="Decline"
                                design="outline"
                                :click-func="() => declineInvitation(invitation)"
                                :busy="respondingToInvitation"
                                :confirm-action="true"
                                confirm-title="Decline Invitation"
                            />
                        </div>
                    </div>
                </li>
                <li
                    v-for="serviceProvider in serviceProviders"
                    :key="serviceProvider.id"
                    class="py-4"
                >
                    <div class="flex items-center space-x-4">
                        <div
                            v-if="serviceProvider.faviconFile"
                            class="shrink-0"
                        >
                            <img
                                class="h-8 w-8 rounded-full"
                                :src="serviceProvider.faviconFile"
                                :alt="serviceProvider.name"
                            >
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium text-gray-900 truncate">
                                {{ serviceProvider.name }}
                            </p>
                            <p class="text-sm text-gray-500 truncate">
                                <span
                                    v-for="role in serviceProvider.roles"
                                    :key="role"
                                >{{ slugToTitleCase(role) }}</span>
                            </p>
                        </div>
                        <div class="flex flex-row space-x-1">
                            <div class="self-center">
                                <base-badge
                                    v-if="serviceProvider.id === currentServiceProvider.id"
                                    size="sm"
                                    :colour="BaseColour.Green"
                                >
                                    Current Account
                                </base-badge>
                            </div>

                            <base-button
                                :icon="CheckCircleIcon"
                                text="Manage"
                                design="outline"
                                :click-func="() => selectServiceProvider(serviceProvider)"
                                :busy="selectingServiceProvider"
                            />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </base-card>
</template>

<script lang="ts" setup>
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { BaseColour } from '@/compiler/types';
import { serviceProviderUserMembershipInvitationInterface, serviceProviderWithRolesInterface } from '@/api/repositories/users';
import API from '@/api';
import useFormatters from '@/composables/useFormatters';
import { useNarrative } from '@/composables/useNarrative';
import { useCurrentUserStore } from '@/stores/currentUser';
import useInertia from '@/composables/useInertia';

const { currentServiceProvider } = useNarrative();
const { slugToTitleCase } = useFormatters();

const serviceProviders = ref<serviceProviderWithRolesInterface[]>([]);
const invitations = ref<serviceProviderUserMembershipInvitationInterface[]>([]);

const loadingServiceProviders = ref(false);
const loadServiceProviders = async () => {
    loadingServiceProviders.value = true;
    serviceProviders.value = await API.repositories.users.fetchServiceProvidersForCurrentUser();
    loadingServiceProviders.value = false;
};

const loadingInvitations = ref(false);
const loadInvitations = async () => {
    loadingInvitations.value = true;
    invitations.value = await API.repositories.users.fetchServiceProviderInvitationsForCurrentUser();
    loadingInvitations.value = false;
};

const respondingToInvitation = ref(false);
const acceptInvitation = (invitation: serviceProviderUserMembershipInvitationInterface) => {
    respondingToInvitation.value = true;
    API.repositories.users.acceptServiceProviderInvitation(invitation.token).then(() => {
        respondingToInvitation.value = false;

        useCurrentUserStore().refreshUserRoles();
        loadServiceProviders();
        loadInvitations();
    });
};

const declineInvitation = (invitation: serviceProviderUserMembershipInvitationInterface) => {
    respondingToInvitation.value = true;
    API.repositories.users.declineServiceProviderInvitation(invitation.token).then(() => {
        respondingToInvitation.value = false;

        loadServiceProviders();
        loadInvitations();
    });
};

const selectingServiceProvider = ref(false);
const selectServiceProvider = (serviceProvider: serviceProviderWithRolesInterface) => {
    selectingServiceProvider.value = true;

    if (serviceProvider.id === currentServiceProvider.value.id) {
        useInertia().visit('/staff');

        return;
    }

    location.href = serviceProvider.url;
};

loadServiceProviders();
loadInvitations();

defineExpose({
    invitations,
});
</script>
