import { archiveService, deleteService, fetchArchivedServices, fetchCompanyService, fetchCompanyServices } from './repositories/companyServices';
import { fetchDomainStatus } from './settings/domain';
import { fetchCarriers } from './settings/carriers';
import { deleteStation } from './repositories/stations';
import { deleteImage } from './repositories/images';
import { deleteAttachment } from './attachments';
import { universalSearch } from './universalSearch/search';
import admin from './admin';
import dashboard from './dashboard';
import addresses from './addresses';
import channels from './channels';
import billing from './repositories/billing';
import inboundShipments from './shipments/inbound';
import outboundShipments from './shipments/outbound';
import repositories from './repositories';
import narrative from './narrative';
import users from './users';
import notifications from './notifications';
import merchants from './merchants';
import serviceProviders from './serviceProviders';
import customerSupportIntegration from './settings/customerSupportIntegration';
import labels from './labels';
import packageItems from './repositories/packageItems';
import printing from './printing';
import serviceProviderBilling from './serviceProviderBilling';
import serviceProviderConfiguration from './serviceProviderConfiguration';
import external from './external';
import reports from './reports';
import orders from './orders';
import inventory from './inventory';
import shipments from './shipments';
import paymentProviders from './repositories/paymentProviders';
import warehouses from './warehouses';

const modelId = (param: string | null = null): number => {
    if (param) {
        const pathParts = location.pathname.split('/');
        const index = param ? pathParts.indexOf(param) + 1 : pathParts.length - 1;
        if (index > 0 && index < pathParts.length) {
            const id = Number.parseInt(pathParts[index]);
            return Number.isNaN(id) ? -1 : id;
        }
    }

    const RegExp = /\d+(?=\D*$)/;
    const match = location.pathname.match(RegExp);
    if (match && match.length) {
        return Number.parseInt(match[0]);
    }

    return -1;
};

const modelUuid = (param: string | null = null): string | null => {
    if (param) {
        const pathParts = location.pathname.split('/');
        const index = param ? pathParts.indexOf(param) + 1 : pathParts.length - 1;
        if (index > 0 && index < pathParts.length) {
            const id = pathParts[index];

            return id;
        }
    }

    const RegExp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|[a-zA-Z0-9]+_[a-zA-Z0-9]+/i;
    const match = location.pathname.match(RegExp);
    if (match && match.length >= 1) {
        return match[0];
    }

    return null;
};

export default {
    deleteService,
    deleteStation,
    fetchCarriers,
    fetchCompanyService,
    fetchCompanyServices,
    fetchDomainStatus,
    modelId,
    modelUuid,
    deleteImage,
    deleteAttachment,
    universalSearch,
    addresses,
    billing,
    channels,
    dashboard,
    inboundShipments,
    labels,
    serviceProviders,
    merchants,
    narrative,
    outboundShipments,
    repositories,
    notifications,
    users,
    archiveService,
    fetchArchivedServices,
    shipments,
    customerSupportIntegration,
    packageItems,
    serviceProviderBilling,
    serviceProviderConfiguration,
    reports,
    external,
    orders,
    admin,
    inventory,
    paymentProviders,
    printing,
    warehouses,
};
