<template>
    <SubscriptionBanner v-if="userIsOwnerOnServiceProvider" />

    <div
        v-if="globalStoresLoaded"
        data-testid="main-layout"
        class="h-screen flex overflow-hidden bg-gray-100"
    >
        <StaffSidebar
            v-if="userHasRoleOnCurrentServiceProvider && !isAdminRoute"
            :open="showSidebar"
            @close="showSidebar = false"
        />

        <ClientSidebar
            v-if="!userHasRoleOnCurrentServiceProvider && !isAdminRoute"
            :open="showSidebar"
            @close="showSidebar = false"
        />

        <AdminSidebar v-if="isAdminRoute" :open="showSidebar" @close="showSidebar = false" />

        <div class="flex flex-col w-0 flex-1 overflow-hidden">
            <UserTopbar @open-sidebar="showSidebar = true" />

            <main class="flex-1 relative overflow-y-auto focus:outline-hidden z-0">
                <div class="py-6">
                    <div class="max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
                        <slot />
                    </div>
                </div>
            </main>
        </div>

        <PopupNotifications />

        <teleport to="body">
            <LargeImage />

            <SessionExpired />

            <BarcodeScanner />

            <BarcodeScanStatus />

            <PrintItemFnskuLabels id="printItemFnskuLabelsSlideover" />
        </teleport>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import StaffSidebar from '../components/layouts/nav/StaffSidebar.vue';
import ClientSidebar from '../components/layouts/nav/ClientSidebar.vue';
import UserTopbar from '../components/layouts/nav/UserTopbar.vue';
import PopupNotifications from '../components/layouts/PopupNotifications.vue';
import LargeImage from '../components/layouts/overlays/LargeImage.vue';
import SessionExpired from '../components/layouts/overlays/SessionExpired.vue';
import BarcodeScanner from '../components/layouts/overlays/BarcodeScanner.vue';
import SubscriptionBanner from '@/components/layouts/overlays/SubscriptionBanner.vue';
import useProviders from '@/composables/useProviders';
import usePopupNotifications from '@/composables/usePopupNotifications';
import { useNarrative } from '@/composables/useNarrative';
import { useNarrativeStore } from '@/stores/narrative';
import { useCurrentUserStore } from '@/stores/currentUser';
import { useChannelsStore } from '@/stores/channels';
import useCustomerSupportIntegration from '@/composables/useCustomerSupportIntegration';
import PrintItemFnskuLabels from '@/components/layouts/overlays/PrintItemFnskuLabels.vue';
import BarcodeScanStatus from '@/components/layouts/overlays/BarcodeScanStatus.vue';
import AdminSidebar from '@/components/layouts/nav/AdminSidebar.vue';

const currentUserStore = useCurrentUserStore();
const channelsStore = useChannelsStore();
const { notifyError } = usePopupNotifications();

const { provideBarcodeListener, provideUserWebsockerListener, provideAnalytics } = useProviders();
const { userHasRoleOnCurrentServiceProvider, userIsOwnerOnServiceProvider, currentMerchantOptional, env, sentryPublicDsn, releaseTag } = useNarrative();

const initGlobalErrorHandling = () => {
    window.addEventListener('unhandledrejection', (event) => {
        if (event.reason === 'Service provider is required') {
            event.preventDefault();

            window.location.href = '/logout';
        }
        if (event.reason === 'Merchant is required') {
            event.preventDefault();

            if (userHasRoleOnCurrentServiceProvider.value) {
                notifyError('You must select a merchant to view this page.');

                return;
            }

            window.location.href = '/select-account';
        }
        if (event.reason === 'User is required') {
            event.preventDefault();

            window.location.href = '/login';
        }
    });
};
initGlobalErrorHandling();

const globalStoresLoaded = ref(false);
const initGlobalStores = async () => {
    const promises = [
        useNarrativeStore().loadNarrative(),
        useNarrativeStore().refreshServiceProvider(),
        currentUserStore.loadCurrentUser(),
        currentUserStore.refreshUserRoles(),
        currentUserStore.loadEntitlements(),
        currentUserStore.refreshPermissions(),
    ];

    await Promise.all(promises).then(() => {
        globalStoresLoaded.value = true;
    });
};

const postInitGlobalStores = async () => {
    if (env.value === 'production' && sentryPublicDsn.value) {
        import('@/composables/useSentry').then((module) => {
            module.default().initSentry(sentryPublicDsn.value!, releaseTag.value!);
        });
    }
};

await initGlobalStores().then(() => {
    postInitGlobalStores();
});

useCustomerSupportIntegration().run();
provideBarcodeListener();
provideUserWebsockerListener();
provideAnalytics();

if (currentMerchantOptional.value) {
    channelsStore.loadChannels();
}

const isAdminRoute = computed(() => window.location.pathname.startsWith('/admin'));

watch(() => currentMerchantOptional.value, (newValue, oldValue) => {
    if (newValue?.id === oldValue?.id) return;

    if (currentMerchantOptional.value) {
        channelsStore.refreshChannels();
    }
});

const showSidebar = ref(false);
</script>
