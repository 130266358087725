import axios from '@/helpers/forms/axios';

export enum OwnerConstraint {
    AmazonOnly = 'AMAZON_ONLY',
    SellerOnly = 'SELLER_ONLY',
    NoneOnly = 'NONE_ONLY',
}

export enum PrepCategory {
    Adult = 'ADULT',
    Baby = 'BABY',
    FcProvided = 'FC_PROVIDED',
    Fragile = 'FRAGILE',
    Granular = 'GRANULAR',
    Hanger = 'HANGER',
    Liquid = 'LIQUID',
    Perforated = 'PERFORATED',
    Set = 'SET',
    Sharp = 'SHARP',
    Small = 'SMALL',
    Textile = 'TEXTILE',
    Unknown = 'UNKNOWN',
    None = 'NONE',
}

export enum PrepType {
    ItemLabeling = 'ITEM_LABELING',
    ItemBubblewrap = 'ITEM_BUBBLEWRAP',
    ItemPolybagging = 'ITEM_POLYBAGGING',
    ItemTaping = 'ITEM_TAPING',
    ItemBlackShrinkwrap = 'ITEM_BLACK_SHRINKWRAP',
    ItemHangGarment = 'ITEM_HANG_GARMENT',
    ItemBoxing = 'ITEM_BOXING',
    ItemSetcreat = 'ITEM_SETCREAT',
    ItemRmovhang = 'ITEM_RMOVHANG',
    ItemSuffostk = 'ITEM_SUFFOSTK',
    ItemCapSealing = 'ITEM_CAP_SEALING',
    ItemDebundle = 'ITEM_DEBUNDLE',
    ItemSetstk = 'ITEM_SETSTK',
    ItemSioc = 'ITEM_SIOC',
    ItemNoPrep = 'ITEM_NO_PREP',
}

export interface FbaTransportV2024ItemPrepDetail {
    msku: string
    allOwnersConstraint: 'MUST_MATCH' | null
    labelOwnerConstraint: OwnerConstraint | null
    prepOwnerConstraint: OwnerConstraint | null
    prepCategory: PrepCategory
    prepTypes: PrepType[]
}

export interface ItemPrepDetailInput {
    msku: string
    prepCategory: PrepCategory
    prepTypes: PrepType[]
}

const fetchPrepDetails = async (channelId: number, mskus: string[], fresh: boolean = false): Promise<FbaTransportV2024ItemPrepDetail[]> => {
    const response = await axios.get<{ data: FbaTransportV2024ItemPrepDetail[] }>('/api/fba-transport/v2024/item-prep-details', {
        params: {
            channel_id: channelId.toString(),
            mskus,
            fresh: fresh ? '1' : '0',
        },
    });

    return response.data.data;
};

const setPrepDetails = async (channelId: number, prepDetails: ItemPrepDetailInput[]): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/item-prep-details`, {
        channelId,
        prepDetails,
    });

    return response.data;
};

export default {
    fetchPrepDetails,
    setPrepDetails,
};
