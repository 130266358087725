import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';

export enum UsageType {
    OutboundShipmentItems = 'outbound_shipment_items',
}

export interface UsageMetricEvent {
    id: string
    event: string
    eventData: object
    occurredAt: string
    usageType: UsageType
    usageEffect: number
}

export interface UsageMetricOutboundShipmentItemEvent extends UsageMetricEvent {
    eventData: {
        itemId: number
        itemSku: string
        quantity: number
        outboundShipmentId: number
        outboundShipmentItemId: number
    }
    usageType: UsageType.OutboundShipmentItems
}

export interface ServiceProviderBillingStatus {
    hasPaymentDueInvoices: boolean
    unpaidInvoicesTotal: {
        amount: number
        currency: string
    }
    hasTrialSubscription: boolean
    trialEndsAt: string | null
}

const fetchUsageMetricEvents = fetchPaginatedDataFunction<UsageMetricEvent>(
    '/api/service-provider-billing/usage-metric-events',
);

const checkStatus = async (): Promise<ServiceProviderBillingStatus | null> => {
    const response = await axios.get<ServiceProviderBillingStatus | null>('/api/service-provider-billing/status');

    return response.data;
};

export default {
    fetchUsageMetricEvents,
    checkStatus,
};
