import { createApp, h } from 'vue';
import '../css/app.css';
import Vue3TouchEvents from 'vue3-touch-events';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createPinia } from 'pinia';
import { registerBaseComponents, registerProviders } from './bootstrap';
import Content from './layouts/Content.vue';
import TooltipDirective from './directives/v-tooltip';
import posthogPlugin from './plugins/posthog';

createInertiaApp({
    resolve: (name) => {
        return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))
            .then((page: any) => {
                page.default.layout = page.default.layout ?? Content;
                return page;
            });
    },
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) });

        const pinia = createPinia();
        vueApp.use(pinia);

        vueApp.use(plugin);
        vueApp.use(posthogPlugin);
        vueApp.use(Vue3TouchEvents);
        vueApp.directive('tooltip', TooltipDirective);

        registerBaseComponents(vueApp);
        registerProviders(vueApp);

        vueApp.mount(el);

        return vueApp;
    },
    progress: {
        color: '#4871ff',
        delay: 100,
    },
});
