import { Currency } from 'dinero.js';
import { companyServiceWithPivotInterface } from '@/api/repositories/companyServices';
import { merchantInterface } from '@/api/repositories/merchants';
import { ServiceProviderPermissionEnum, serviceProviderInterface } from '@/api/repositories/serviceProviders';
import { warehouseInterface } from '@/api/warehouses';
import { tableItemInterface } from '@/helpers/tables';
import { AnalyticsKeys } from '@/composables/useAnalytics';
import { Attachment } from '@/api/attachments';

export enum AppEntitlementEnum {
    BaseAccess = 'base_access',
    Locations = 'locations',
    AmazonShipments = 'amazon_shipments',
    Orders = 'orders',
}

export type AppPermissionEnum = ServiceProviderPermissionEnum | 'viewCompanyServicesPrice' | 'editCompanyService';

export interface Spark {
    env: 'local' | 'production' | 'demo' | 'testing' | 'staging'
    stripeKey: string
    appName: string
    appBaseDomain: string
    serviceProvider?: serviceProviderInterface
    merchant?: merchantInterface
    impersonatingUserId: number | null
    pusherAppKey?: string
    pusherHost?: string
    sentryPublicDsn?: string
    releaseTag?: string
    analyticsKeys: AnalyticsKeys
}

export enum BaseColour {
    Primary = 'primary',
    Indigo = 'indigo',
    Gray = 'gray',
    Red = 'red',
    Yellow = 'amber',
    Teal = 'teal',
    Green = 'green',
    Blue = 'blue',
}

export interface baseModelInterface {
    id: number
    createdAt: string
    updatedAt: string
}

export interface sharedShipmentInterface extends baseModelInterface {
    name: string
    teamId: number
    notes: string
    internalNotes: string
    companyServices: companyServiceWithPivotInterface[]
    attachments?: Attachment[]
    warehouseId: number
    warehouse?: warehouseInterface
}

export interface dropdownItemInterface {
    text: string
    href?: string
    icon?: Function
    clickFunc?: Function
}

export interface notesFormInterface {
    notes: string
}

export interface internalNotesFormInterface {
    internalNotes: string
}

export interface shipmentNameFormInterface {
    name: string
}

export interface shipmentNotesFormInterface extends notesFormInterface, internalNotesFormInterface { }

export interface SelectOption {
    value: string | number | boolean
    name: string
    disabled?: boolean
}

export interface navigationInterface {
    name: string
    href: string
    icon: Function
    activeIf: RegExp
    count?: number
}

export interface defaultIntertiaPropsInterface {
    errors: { [key: string]: string }
    flash: {
        successMessages: string[]
        errorMessages: string[]
    }
}

export interface moneyInterface {
    amount: number
    currency: Currency
}

export interface requestFilterInterface {
    name: string
    value: string
}

export interface DataFetcherSort {
    field: string
    direction: 'asc' | 'desc'
    order: number
}

export interface DataFetcherResponse<TData> {
    data: TData[]
    total: number
    perPage: number
    currentPage: number
    lastPage: number
    from: number
    to: number
}

export interface DataFetcherFunction<TData> {
    (page: number, pageSize: number, sorts: DataFetcherSort[], filters: string): Promise<DataFetcherResponse<TData>>
}

export interface PaginatedRequestFunction<T> {
    (page: number, filters?: requestFilterInterface[]): Promise<PaginatedResults<T>>
};

export interface PaginatedResults<T> {
    data: (T & tableItemInterface)[]
    total: number
    perPage: number
    currentPage: number
    lastPage: number
    firstPageUrl: string
    lastPageUrl: string
    nextPageUrl: string
    prevPageUrl: string
    path: string
    from: number
    to: number
};
