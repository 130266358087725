<template>
    <Menu id="helpDropdownMenu" as="div" class="mx-1 relative hidden sm:block">
        <span v-if="headwayUnseenCount" class="flex absolute h-2 w-2 top-0.5 right-0.5">
            <span
                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-400 opacity-75"
            />
            <span class="relative inline-flex rounded-full h-2 w-2 bg-pink-500" />
        </span>
        <div>
            <MenuButton
                class="inline-flex flex-row bg-gray-800 hover:bg-gray-300 p-1.5 rounded-lg text-gray-400 hover:text-gray-600 focus:outline-hidden focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 focus:ring-primary-500"
                dusk="help-nav-button"
                @click="initHeadway"
            >
                <span class="sr-only">Open help menu</span>
                <QuestionMarkCircleIcon class="h-6 w-6" aria-hidden="true" />
                <span
                    v-if="!userHasRoleOnCurrentServiceProvider"
                    class="hidden sm:inline-block self-center ml-1"
                >Help</span>
            </MenuButton>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
        >
            <MenuItems
                class="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black/5 divide-y divide-gray-100 focus:outline-hidden"
            >
                <div v-if="getTourFromRoute" class="py-1">
                    <h3
                        class="my-1 px-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider"
                    >
                        Page
                        Help
                    </h3>
                    <MenuItem v-slot="{ active }">
                        <a
                            class="group flex items-center px-4 py-2 text-sm cursor-pointer" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']"
                            @click="openPageHelp"
                        >Open Page Guide</a>
                    </MenuItem>
                </div>
                <div class="py-1">
                    <h3
                        class="mt-3 mb-1 px-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider"
                    >
                        General Help
                    </h3>
                    <MenuItem v-slot="{ active }">
                        <a
                            class="group flex items-center px-3 py-2 text-sm cursor-pointer" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']"
                            @click="restartUserSetup()"
                        >Restart Features Guide</a>
                    </MenuItem>
                    <MenuItem v-if="userIsOwnerOnServiceProvider" v-slot="{ active }">
                        <a
                            class="group flex items-center px-3 py-2 text-sm cursor-pointer" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']"
                            @click="restartAdminSetup()"
                        >Restart Setup Guide</a>
                    </MenuItem>
                    <MenuItem v-if="userHasRoleOnCurrentServiceProvider" v-slot="{ active }">
                        <a
                            :href="`https://help.${appName}.com`"
                            class="group flex items-center px-3 py-2 text-sm" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']"
                        >View Help Desk</a>
                    </MenuItem>
                </div>
                <div v-if="userHasRoleOnCurrentServiceProvider" class="py-1">
                    <h3
                        class="mt-3 mb-1 px-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider"
                    >
                        Updates
                    </h3>

                    <a
                        id="whatsNewMenuLink"
                        href="#whatsnew"
                        class="text-gray-700 group flex items-center px-3 py-2 text-sm"
                    >
                        <LightBulbIcon
                            class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                        />What's New
                    </a>

                    <a
                        :href="`https://feedback.${appName}.com`"
                        class="text-gray-700 group flex items-center px-3 py-2 text-sm"
                    >
                        <MapIcon
                            class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                        />Roadmap & Feature Requests
                    </a>
                </div>
                <div class="py-1">
                    <h3
                        class="mt-3 mb-1 px-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider"
                    >
                        Support
                    </h3>

                    <a
                        href="#emailus"
                        class="text-gray-700 group flex items-center px-3 py-2 text-sm"
                        @click="openSupportForm"
                    >
                        <EnvelopeIcon
                            class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                        />Email Us
                    </a>

                    <h3
                        v-if="releaseTag"
                        class="mt-1 px-3 text-xs font-semibold text-gray-400 -mb-1"
                    >
                        Release Version
                    </h3>
                    <span
                        v-if="releaseTag"
                        class="px-3 text-xs text-gray-400"
                    >{{ releaseTag }}</span>
                </div>
            </MenuItems>
        </transition>
    </Menu>

    <teleport to="body">
        <SupportForm :open="showSupportForm" @close="closeSupportForm" />
    </teleport>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { EnvelopeIcon, LightBulbIcon, MapIcon, QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';
import { usePage } from '@inertiajs/vue3';
import SupportForm from '../overlays/SupportForm.vue';
import useUserOnboarding, { availableToursEnum } from '@/composables/useUserOnboarding';
import useInertia from '@/composables/useInertia';
import { useNarrative } from '@/composables/useNarrative';

const { appName, userHasRoleOnCurrentServiceProvider, userIsOwnerOnServiceProvider, releaseTag } = useNarrative();

const { startTour, resetTour } = useUserOnboarding();

const openPageHelp = () => {
    const tour = getTourFromRoute.value;
    if (!tour) {
        return;
    }
    resetTour(tour).then(() => {
        startTour(tour);
    });
};

const getTourFromRoute = computed((): availableToursEnum | null => {
    const route = usePage().url!;

    if (route.endsWith('/shipments/inbound')) {
        return availableToursEnum.ShipmentIndex;
    }
    if (route.endsWith('/shipments/outbound')) {
        return availableToursEnum.ShipmentIndex;
    }
    if (route.includes('/shipments/inbound')) {
        return availableToursEnum.InboundShipment;
    }
    if (route.includes('/shipments/outbound')) {
        return availableToursEnum.OutboundShipment;
    }
    if (route.endsWith('/inventory')) {
        return availableToursEnum.Inventory;
    }
    if (route.endsWith('/billing')) {
        return availableToursEnum.Billing;
    }

    return null;
});

const showSupportForm = ref(false);
const openSupportForm = () => {
    showSupportForm.value = true;
};
const closeSupportForm = () => {
    showSupportForm.value = false;
};

const restartAdminSetup = () => {
    if (!userIsOwnerOnServiceProvider.value) {
        return;
    }
    resetTour(availableToursEnum.CompanyAdmin).then(() => {
        if (location.pathname !== '/staff') {
            useInertia().visit('/staff');
        } else {
            startTour(availableToursEnum.CompanyAdmin);
        }
    });
};

const restartUserSetup = () => {
    if (userHasRoleOnCurrentServiceProvider.value) {
        resetTour(availableToursEnum.CompanyUser).then(() => {
            if (location.pathname !== '/staff') {
                useInertia().visit('/staff');
            } else {
                startTour(availableToursEnum.CompanyUser);
            }
        });
    } else {
        resetTour(availableToursEnum.ClientUser).then(() => {
            if (location.pathname !== '/dashboard') {
                useInertia().visit('/dashboard');
            } else {
                startTour(availableToursEnum.ClientUser);
            }
        });
    }
};

const headway = ref<any | undefined>();

const headwayUnseenCount = ref(0);
onMounted(() => {
    if (!userHasRoleOnCurrentServiceProvider.value) {
        return;
    }
    headway.value = (window as any).Headway;
    if (!headway.value) return;
    // Load headway data so that a badge can be shown on the help menu without triggering the headway popup
    headway.value.init({
        selector: '#helpDropdownMenu', // CSS selector where to inject the badge
        trigger: '#whatsNewMenuLink',
        account: 'xYZQo7',
        callbacks: {
            onWidgetReady: () => {
                headwayUnseenCount.value = headway.value.unseenCount;
                headway.value.destroy();
            },
        },
    });
});

const initHeadway = () => {
    if (!userHasRoleOnCurrentServiceProvider.value) {
        return;
    }

    if (!headway.value) return;

    setTimeout(() => {
        headway.value.init({
            selector: '#whatsNewMenuLink', // CSS selector where to inject the badge
            trigger: '#whatsNewMenuLink',
            account: 'xYZQo7',
            callbacks: {
                onShowWidget: () => {
                    headwayUnseenCount.value = 0;
                },
            },
        });
    }, 100);
};
</script>
