import axios from '@/helpers/forms/axios';

export interface Attachment {
    id: number
    createdAt: string
    updatedAt: string
    path: string
    name: string
    url: string
};

export const deleteAttachment = async (attachmentId: number): Promise<void> => {
    return await axios.delete(`/api/attachments/${attachmentId}`);
};
