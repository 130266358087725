<template>
    <TransitionRoot
        as="template"
        :show="open"
    >
        <Dialog
            as="div"
            static
            class="fixed inset-0 flex z-40 md:hidden"
            :open="open"
            @close="closeSidebar"
        >
            <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 flex z-40">
                <TransitionChild
                    as="template"
                    enter="transition ease-in-out duration-300"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-300"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full"
                >
                    <DialogPanel class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                        <TransitionChild
                            as="template"
                            enter="ease-in-out duration-300"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="ease-in-out duration-300"
                            leave-from="opacity-100"
                            leave-to="opacity-0"
                        >
                            <div class="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-hidden focus:ring-2 focus:ring-inset focus:ring-white"
                                    @click="closeSidebar"
                                >
                                    <span class="sr-only">Close sidebar</span>
                                    <XMarkIcon
                                        class="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </TransitionChild>
                        <div class="flex items-center h-16 shrink-0 px-4 bg-white">
                            <a
                                href="#"
                                @click="visitAndCloseSidebar('/admin')"
                            >
                                <img
                                    class="h-10 w-auto"
                                    src="@/static/img/Conveyr-Horizontal-Color-SVG.svg"
                                    alt="Conveyr Logo"
                                >
                            </a>
                        </div>
                        <div class="mt-5 flex-1 h-0 overflow-y-auto">
                            <nav class="px-2 space-y-1">
                                <a
                                    v-for="item in navigation"
                                    :key="item.name"
                                    href="#"
                                    class="group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                    :class="[
                                        pageUrl.match(item.activeIf)
                                            ? 'bg-gray-900 text-white'
                                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    ]" @click="visitAndCloseSidebar(item.href)"
                                >
                                    <component
                                        :is="item.icon as any"
                                        class="mr-4 shrink-0 h-6 w-6" :class="[
                                            pageUrl.match(item.activeIf) ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                        ]"
                                        aria-hidden="true"
                                    />
                                    {{ item.name }}
                                </a>
                            </nav>
                        </div>
                    </DialogPanel>
                </TransitionChild>
                <div
                    class="shrink-0 w-14"
                    aria-hidden="true"
                >
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
        class="hidden md:flex md:shrink-0"
        v-bind="$attrs"
    >
        <div class="flex flex-col w-64">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col h-0 flex-1">
                <div class="flex items-center h-16 shrink-0 px-4 bg-white">
                    <BaseLink href="/admin">
                        <img
                            class="h-10 w-auto"
                            src="@/static/img/Conveyr-Horizontal-Color-SVG.svg"
                            alt="Conveyr Logo"
                        >
                    </BaseLink>
                </div>
                <div class="flex-1 flex flex-col overflow-y-auto">
                    <nav class="flex-1 px-2 py-4 bg-gray-800 space-y-1">
                        <BaseLink
                            v-for="item in navigation"
                            :key="item.name"
                            :href="item.href"
                            class="group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="[
                                pageUrl.match(item.activeIf)
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            ]"
                        >
                            <component
                                :is="item.icon as any"
                                class="mr-3 shrink-0 h-6 w-6" :class="[
                                    pageUrl.match(item.activeIf) ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                ]"
                                aria-hidden="true"
                            />
                            {{ item.name }}
                        </BaseLink>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { BanknotesIcon, BookOpenIcon, HomeIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { usePage } from '@inertiajs/vue3';
import { ArrowRightIcon, LockOpenIcon } from '@heroicons/vue/20/solid';
import { navigationInterface } from '@/compiler/types';
import useInertia from '@/composables/useInertia';
import BaseLink from '@/components/elements/BaseLink.vue';

defineProps({
    open: Boolean,
});

const emit = defineEmits<{
    (e: 'close'): void
}>();

const visitAndCloseSidebar = (href: string) => {
    useInertia().visit(href);
    closeSidebar();
};

const closeSidebar = () => {
    emit('close');
};

const pageUrl = computed(() => usePage().url!);

const dashboard = { name: 'Dashboard', href: '/admin', icon: HomeIcon, activeIf: /\/admin$/ };
const users = { name: 'Users', href: '/admin/users', icon: BookOpenIcon, activeIf: /.*admin\/users.*/ };
const serviceProviderBilling = { name: 'Service Provider Billing', href: '/admin/serviceProviderBilling', icon: BanknotesIcon, activeIf: /.*admin\/serviceProviderBilling.*/ };
const serviceProviderEntitlements = { name: 'Service Provider Entitlements', href: '/admin/serviceProviderEntitlements', icon: LockOpenIcon, activeIf: /.*admin\/serviceProviderEntitlements.*/ };
const amazonRequests = { name: 'Amazon Requests', href: '/admin/amazonRequests', icon: ArrowRightIcon, activeIf: /.*admin\/amazonRequests.*/ };

const navigation = computed<navigationInterface[]>(() => [
    dashboard,
    users,
    serviceProviderBilling,
    serviceProviderEntitlements,
    amazonRequests,
]);
</script>
