<template>
    <BaseBadge v-if="operationsStore.operationChecks[operation.getId()].relativeLastCheck && operation.getStatus() === OperationStatus.InProgress">
        <span>Last Checked At: {{ operationsStore.operationChecks[operation.getId()].relativeLastCheck }}</span>
        <ArrowPathIcon
            class="h-3.5 w-3.5 mx-1 cursor-pointer hover:opacity-75" :class="{
                'animate-spin': operationsStore.operationChecks[operation.getId()].checking,
            }" @click="operationsStore.operationChecks[operation.getId()].check"
        />
    </BaseBadge>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { ArrowPathIcon } from '@heroicons/vue/20/solid';
import BaseBadge from '@/components/elements/BaseBadge.vue';
import { Operation, OperationStatus, useOperationsStore } from '@/stores/operations/operations';

defineProps({
    operation: {
        type: Object as PropType<Operation>,
        required: true,
    },
});

const operationsStore = useOperationsStore();
</script>
