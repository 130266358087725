<template>
    <Listbox v-model="selectedOption" :disabled="disabled" as="div">
        <ListboxLabel v-if="label" class="block text-sm font-medium text-gray-700">
            {{ label }}
        </ListboxLabel>
        <div class="relative" :class="{ 'mt-1': label }">
            <ListboxButton :class="buttonClasses">
                <span class="block truncate">{{ selectedOption.name }}</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>

                <!-- Error Icon -->
                <span
                    v-if="isForm && form.errors.has(formProp)"
                    class="absolute inset-y-0 right-0 flex items-center pr-10 pointer-events-none"
                >
                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </span>
            </ListboxButton>

            <ListboxOptions
                :class="`floatingUiElement-${selectId}`"
                class="z-10 fixed bg-white shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black/5 overflow-auto focus:outline-hidden text-sm"
            >
                <ListboxOption
                    v-for="(option, index) in options"
                    :key="index"
                    v-slot="{ active, selected }"
                    :required="required"
                    :autofocus="autofocus"
                    :disabled="option?.disabled"
                    as="template"
                    :value="option"
                >
                    <li
                        class="cursor-default select-none relative py-2 pl-3 pr-9" :class="[active ? 'text-white bg-primary-600' : 'text-gray-900']"
                        :data-select-value="option.value"
                    >
                        <span
                            class="block truncate" :class="[selected ? 'font-semibold' : 'font-normal']"
                        >
                            {{
                                option.name
                            }}
                        </span>

                        <span
                            v-if="selected"
                            class="absolute inset-y-0 right-0 flex items-center pr-4" :class="[active ? 'text-white' : 'text-primary-600']"
                        >
                            <CheckIcon v-if="useChecks" class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                </ListboxOption>
            </ListboxOptions>

            <!-- Errors don't show if this field not present, weird Vue issue -->
            <span v-if="form && form.busy" class="hidden" />
        </div>

        <!-- Error Text -->
        <p v-if="isForm && form.errors.has(formProp)" class="mt-1 text-sm text-red-600">
            <span>{{ form.errors.get(formProp) }}</span>
        </p>
    </Listbox>
</template>

<script lang="ts" setup>
import { PropType, Ref, computed, ref, watch } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronDownIcon, ExclamationCircleIcon } from '@heroicons/vue/20/solid';
import { Placement } from '@floating-ui/core';
import get from 'lodash/get';
import set from 'lodash/set';
import { SparkFormType } from '@/helpers/forms/form';
import { SelectOption } from '@/compiler/types';
import useFloatingUi from '@/composables/useFloatingUi';

const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    options: {
        type: Array as PropType<Array<SelectOption>>,
        required: true,
    },
    optionsPlacement: {
        type: String as PropType<Placement>,
        default: 'bottom-start',
    },
    form: {
        type: Object as PropType<SparkFormType<any>>,
        default: null,
    },
    formProp: {
        type: String,
        default: null,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    useChecks: {
        type: Boolean,
        default: true,
    },
    resetOnSelect: {
        type: Boolean,
        default: false,
    },
    value: {
        type: [String, Number],
        default: null,
    },
    fallbackOption: {
        type: Object as PropType<SelectOption>,
        default: () => ({
            name: 'Loading Options...',
            value: 0,
        }),
    },
});

const selectId = `baseSelect-${Math.random().toString(36).slice(-5)}`;

const buttonClasses = computed(() => {
    let classes = 'bg-white relative w-full border rounded-md shadow-xs pl-3 pr-10 py-2 text-left cursor-default focus:outline-hidden focus:ring-1 text-sm';

    if (props.form && props.form.errors.has(props.formProp)) {
        classes += ' pr-10 border-red-300 focus:ring-red-500 focus:border-red-500';
    } else {
        classes += ' border-gray-300 focus:ring-primary-500 focus:border-primary-500';
    }

    if (props.disabled) {
        classes += ' cursor-not-allowed';
    }

    classes += ` floatingUiTrigger-${selectId}`;

    return classes;
});

const initialOption = () => {
    if (props.options && props.options.length) {
        return props.options[0];
    } else {
        return props.fallbackOption;
    }
};

const shouldEmit = ref(false);
const selectedOption = ref(initialOption()) as Ref<SelectOption>;
const model = defineModel<null | string | boolean | number>();

const isForm = computed(() => {
    return Boolean(props.form && props.formProp);
});

const computePosition = () => {
    useFloatingUi(selectId, {
        placement: props.optionsPlacement,
    }, {
        atLeastSameSizeAsReference: true,
    });
};

const setOptionFromProps = () => {
    let requestedValue: null | string | boolean | number = null;
    const formPropValue = get(props.form, props.formProp);
    if (formPropValue && formPropValue !== selectedOption.value) {
        requestedValue = get(props.form, props.formProp) ?? null;
    } else if (props.value && selectedOption.value.value !== props.value) {
        requestedValue = props.value;
    } else if (model.value && selectedOption.value.value !== model.value) {
        requestedValue = model.value;
    }

    if (requestedValue === null) return;

    const foundOption = props.options.find(option => option.value === requestedValue);
    if (foundOption) {
        selectedOption.value = foundOption;
    }

    setTimeout(() => {
        shouldEmit.value = true;
    }, 200);
};

const ensureSelectedOptionIsValid = () => {
    const isValidOption = props.options.find(option => option.value === selectedOption.value.value);

    if (!isValidOption && props.options.length) {
        selectedOption.value = props.options[0];
    }
};

watch(selectedOption, (newValue: SelectOption) => {
    if (!newValue) {
        return;
    }

    if (props.form) {
        set(props.form, props.formProp, newValue.value);
    }
    if (model.value !== newValue.value) {
        model.value = newValue.value;
    }

    if (props.resetOnSelect && selectedOption.value.value !== props.options[0].value) {
        selectedOption.value = props.options[0];
    }
});

watch(() => model.value, (newValue) => {
    if (!newValue) {
        return;
    }

    const foundOption = props.options.find(option => option.value === newValue);

    if (foundOption) {
        selectedOption.value = foundOption;
    }
});

watch(props, () => {
    setOptionFromProps();
    ensureSelectedOptionIsValid();
    computePosition();
}, { deep: true });

setOptionFromProps();
computePosition();
</script>
