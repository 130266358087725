<template>
    <div :class="divClasses" role="alert">
        <div class="flex">
            <div v-if="showIcon" class="shrink-0">
                <!-- Icon -->
                <component :is="icon" class="h-5 w-5" :class="`text-${colour}-400`" />
            </div>
            <div :class="showIcon ? 'ml-3' : ''">
                <h3
                    v-if="title"
                    class="text-xs leading-5 font-medium"
                    :class="`text-${colour}-800`"
                >
                    {{ title }}
                </h3>

                <div :class="slotClasses" class="text-sm leading-5">
                    <slot />
                </div>
            </div>
            <div v-if="closeFunc" class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <button
                        class="inline-flex rounded-md p-1.5 focus:outline-hidden transition ease-in-out duration-150"
                        :class="`text-${colour}-500 hover:bg-${colour}-100 focus:bg-${colour}-100`"
                        @click="close"
                    >
                        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue';
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import { BaseColour } from '@/compiler/types';

const props = defineProps({
    title: {
        type: String,
        default: null,
    },
    colour: {
        type: String as PropType<BaseColour>,
        default: BaseColour.Primary,
    },
    class: {
        type: String,
        default: '',
    },
    closeFunc: {
        type: Function,
        default: null,
    },
    showIcon: {
        type: Boolean,
        default: true,
    },
});

const divClasses = computed(() => {
    let classes = `rounded-md bg-${props.colour}-50 p-4 `;
    classes += props.class;

    return classes;
});
const slotClasses = computed(() => {
    const classes = `text-${props.colour}-600 `;

    return classes;
});
const icon = computed(() => {
    const colourIcons: { [key: string]: Function } = {
        indigo: InformationCircleIcon,
        primary: InformationCircleIcon,
        gray: InformationCircleIcon,
        green: CheckCircleIcon,
        red: ExclamationCircleIcon,
        amber: ExclamationCircleIcon,
        orange: ExclamationCircleIcon,
    };

    return colourIcons[props.colour];
});

const close = () => {
    props.closeFunc();
};
/*
Specify classes to prevent purgeCSS from stripping th       bg-indigo-50 bg-teal-50 bg-red-50 bg-amber-50 bg-amber-50 bg-green-50 bg-primary-       hover:bg-indigo-100 hover:bg-teal-100 hover:bg-red-100 hover:bg-amber-100 hover:bg-amber-100 hover:bg-green-100 hover:bg-primary-1       focus:bg-indigo-100 focus:bg-teal-100 focus:bg-red-100 focus:bg-amber-100 focus:bg-amber-100 focus:bg-green-100 focus:bg-primary-1       text-indigo-400 text-teal-400 text-red-400 text-amber-400 text-amber-400 text-green-400 text-primary-4       text-indigo-500 text-teal-500 text-red-500 text-amber-500 text-amber-500 text-green-500 text-primary-5       text-indigo-600 text-teal-600 text-red-600 text-amber-600 text-amber-600 text-green-600 text-primary-6       text-indigo-800 text-teal-800 text-red-800 text-amber-800 text-amber-800 text-green-800 text-primary-6       text-indigo-700 text-teal-700 text-red-700 text-amber-700 text-amber-700 text-green-700 text-primary-700
*/
</script>
