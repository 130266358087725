<template>
    <TransitionRoot
        as="template"
        :show="open"
    >
        <Dialog
            as="div"
            static
            class="fixed inset-0 flex z-40 md:hidden"
            :open="open"
            @close="closeSidebar"
        >
            <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 flex z-40">
                <TransitionChild
                    as="template"
                    enter="transition ease-in-out duration-300"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-300"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full"
                >
                    <DialogPanel class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                        <TransitionChild
                            as="template"
                            enter="ease-in-out duration-300"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="ease-in-out duration-300"
                            leave-from="opacity-100"
                            leave-to="opacity-0"
                        >
                            <div class="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-hidden focus:ring-2 focus:ring-inset focus:ring-white"
                                    @click="closeSidebar"
                                >
                                    <span class="sr-only">Close sidebar</span>
                                    <XMarkIcon
                                        class="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </TransitionChild>
                        <div
                            class="flex items-center h-16 shrink-0 px-4"
                            :class="currentServiceProvider.logoFile ? currentServiceProvider.logoBackground : 'bg-gray-800'"
                        >
                            <a
                                href="#"
                                @click="visitAndCloseSidebar('/staff')"
                            >
                                <h3
                                    v-if="!currentServiceProvider.logoFile"
                                    class="text-white"
                                >{{ currentServiceProvider.name }} Portal</h3>
                                <img
                                    v-if="currentServiceProvider.logoFile"
                                    class="h-10 w-auto"
                                    :src="logoUrl"
                                    :alt="currentServiceProvider.name"
                                    dusk="logoImg"
                                >
                            </a>
                        </div>
                        <div class="mt-5 flex-1 h-0 overflow-y-auto">
                            <nav class="px-2 space-y-1">
                                <BaseLink
                                    v-for="item in navigation"
                                    :key="item.name"
                                    href="#"
                                    class="group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                    :class="[
                                        pageUrl.match(item.activeIf)
                                            ? 'bg-gray-900 text-white'
                                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    ]" @click="visitAndCloseSidebar(item.href)"
                                >
                                    <component
                                        :is="item.icon as any"
                                        class="mr-4 shrink-0 h-6 w-6" :class="[
                                            pageUrl.match(item.activeIf) ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                        ]"
                                        aria-hidden="true"
                                    />
                                    {{ item.name }}
                                </BaseLink>

                                <h3
                                    class="pt-4 pb-1 px-3 text-sm leading-4 font-semibold text-gray-300 uppercase tracking-wider"
                                >
                                    Actions
                                </h3>

                                <BaseLink
                                    v-for="item in actionsNavigation"
                                    :key="item.name"
                                    href="#"
                                    class="group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                    :class="[
                                        pageUrl.match(item.activeIf)
                                            ? 'bg-gray-900 text-white'
                                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    ]" @click="visitAndCloseSidebar(item.href)"
                                >
                                    <component
                                        :is="item.icon as any"
                                        class="mr-4 shrink-0 h-6 w-6" :class="[
                                            pageUrl.match(item.activeIf) ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                        ]"
                                        aria-hidden="true"
                                    />
                                    {{ item.name }}
                                </BaseLink>
                            </nav>
                        </div>
                    </DialogPanel>
                </TransitionChild>
                <div
                    class="shrink-0 w-14"
                    aria-hidden="true"
                >
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
        class="hidden md:flex md:shrink-0"
        v-bind="$attrs"
    >
        <div class="flex flex-col w-64">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col h-0 flex-1">
                <div
                    class="flex items-center h-16 shrink-0 px-4"
                    :class="currentServiceProvider.logoFile ? currentServiceProvider.logoBackground : 'bg-gray-800'"
                >
                    <BaseLink href="/dashboard">
                        <h3
                            v-if="!currentServiceProvider.logoFile"
                            class="text-white"
                        >
                            {{ currentServiceProvider.name }} Portal
                        </h3>
                        <img
                            v-if="currentServiceProvider.logoFile"
                            class="h-10 w-auto"
                            :src="logoUrl"
                            :alt="currentServiceProvider.name"
                        >
                    </BaseLink>
                </div>
                <div class="flex-1 flex flex-col overflow-y-auto">
                    <nav class="flex-1 px-2 py-4 bg-gray-800 space-y-1">
                        <BaseLink
                            v-for="item in navigation"
                            :key="item.name"
                            :href="item.href"
                            class="group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="[
                                pageUrl.match(item.activeIf)
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            ]"
                        >
                            <component
                                :is="item.icon as any"
                                class="mr-3 shrink-0 h-6 w-6" :class="[
                                    pageUrl.match(item.activeIf) ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                ]"
                                aria-hidden="true"
                            />
                            {{ item.name }}
                        </BaseLink>

                        <h3 class="pt-4 pb-1 px-3 text-sm leading-4 font-semibold text-gray-300 uppercase tracking-wider">
                            Actions
                        </h3>

                        <BaseLink
                            v-for="item in actionsNavigation"
                            :key="item.name"
                            :href="item.href"
                            class="group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="[
                                pageUrl.match(item.activeIf)
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            ]"
                        >
                            <component
                                :is="item.icon as any"
                                class="mr-3 shrink-0 h-6 w-6" :class="[
                                    pageUrl.match(item.activeIf) ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                ]"
                                aria-hidden="true"
                            />
                            {{ item.name }}
                            <base-badge
                                v-if="item.count !== undefined"
                                :colour="item.count ? BaseColour.Yellow : BaseColour.Gray"
                                class="ml-2"
                            >
                                {{ item.count }}
                            </base-badge>
                        </BaseLink>
                    </nav>
                </div>

                <ClientOwnerSetupProgress v-if="userIsOwnerOnMerchant" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';
import {
    BanknotesIcon,
    CogIcon,
    ExclamationCircleIcon,
    HomeIcon,
    InboxArrowDownIcon,
    InformationCircleIcon,
    PlusCircleIcon,
    RectangleStackIcon,
    ShoppingBagIcon,
    TruckIcon,
    XMarkIcon,
} from '@heroicons/vue/24/outline';
import { usePage } from '@inertiajs/vue3';
import ClientOwnerSetupProgress from './ClientOwnerSetupProgress.vue';
import { InventoryIcon } from '@/helpers/icons';
import { BaseColour, navigationInterface } from '@/compiler/types';
import API from '@/api';
import useAppEntitlements from '@/composables/useAppEntitlements';
import useInertia from '@/composables/useInertia';
import { useNarrative } from '@/composables/useNarrative';
import BaseLink from '@/components/elements/BaseLink.vue';

defineProps({
    open: Boolean,
});

const emit = defineEmits<{
    (e: 'close'): void
}>();

const { currentServiceProvider, currentMerchant, userIsOwnerOnMerchant, logoUrl } = useNarrative();

const { hasOrdersEntitlement } = useAppEntitlements();
const fixCount = ref(0);

const visitAndCloseSidebar = (href: string) => {
    useInertia().visit(href);
    closeSidebar();
};

const closeSidebar = () => {
    emit('close');
};

const pageUrl = computed(() => usePage().url!);

const dashboard = { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, activeIf: /\/dashboard$/ };
const inboundShipments = { name: 'Inbound Shipments', href: '/shipments/inbound', icon: InboxArrowDownIcon, activeIf: /.*shipments\/inbound.*/ };
const outboundShipments = { name: 'Outbound Shipments', href: '/shipments/outbound', icon: TruckIcon, activeIf: /.*shipments\/outbound.*/ };
const inventory = { name: 'Inventory', href: '/inventory', icon: InventoryIcon, activeIf: /.*inventory.*/ };
const channels = { name: 'Channels', href: '/channels', icon: ShoppingBagIcon, activeIf: /.*channels.*/ };
const billing = { name: 'Billing', href: '/billing', icon: BanknotesIcon, activeIf: /.*billing.*/ };
const training = { name: 'Training', href: '/actions/training', icon: InformationCircleIcon, activeIf: /.*actions\/training.*/ };
const merchantSettings = { name: 'Merchant Settings', href: '/merchant-settings/', icon: CogIcon, activeIf: /.*merchant-settings.*/ };
const orders = { name: 'Orders', href: '/orders', icon: RectangleStackIcon, activeIf: /.*orders.*/ };
const fixIssues = (fixCount: number) => ({ name: 'Fix Issues', href: '/actions/fix-items', icon: ExclamationCircleIcon, activeIf: /.*actions\/fix-items.*/, count: fixCount });
const newShipment = { name: 'New Shipment', href: '/shipments/inbound/create', icon: PlusCircleIcon, activeIf: /.*shipments\/inbound\/create.*/ };

const navigation = computed((): navigationInterface[] => [
    dashboard,
    inboundShipments,
    outboundShipments,
    ...hasOrdersEntitlement.value ? [orders] : [],
    inventory,
    channels,
    ...userIsOwnerOnMerchant.value ? [billing] : [],
    training,
    ...userIsOwnerOnMerchant.value ? [merchantSettings] : [],
]);

const actionsNavigation = computed<navigationInterface[]>(() => [
    fixIssues(fixCount.value),
    newShipment,
]);

API.merchants.getFixCount(currentMerchant.value.id).then((fixCountResponse) => {
    fixCount.value = fixCountResponse;
});
</script>
