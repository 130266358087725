<template>
    <fieldset
        class="flex flex-col border-primary-500 border px-3 py-2 rounded-sm min-w-[10em] mt-1 bg-white"
    >
        <div class="flex items-center w-full justify-between">
            <div class="flex items-center space-x-2">
                <OperationStatusBadge :operation="operation" />
                <OperationCheckBadge :operation="operation" />
            </div>

            <OperationAction v-if="isActionable(operation) && operation.getStatus() === OperationStatus.Succeeded" :operation="operation" />
        </div>

        <div class="flex items-center space-x-2">
            <h2 class="min-w-0 text-sm font-semibold leading-6 text-gray-400">
                <p class="flex gap-x-2">
                    <span class="truncate">{{ operation.getDescription() }}</span>
                </p>
            </h2>
        </div>

        <div class="min-w-0 flex justify-between">
            <div class="flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                <p class="truncate">
                    <b>Operation ID: </b><CopyToClipboard :text="isActionable(operation) ? reverseTruncate(operation.getId(), 12) : operation.getId()" :value="operation.getId()" />
                </p>
                <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 flex-none fill-gray-300">
                    <circle cx="1" cy="1" r="1" />
                </svg>
            </div>
        </div>

        <BaseAlert v-if="operation.getErrors().length" title="Operation Issues" :colour="BaseColour.Red">
            <ul role="list">
                <li v-for="error in operation.getErrors()" :key="error.code">
                    {{ error.message }}
                </li>
            </ul>
        </BaseAlert>

        <BaseAlert v-if="operation.getWarnings().length" title="Operation Warnings" :colour="BaseColour.Yellow">
            <ul role="list">
                <li v-for="warning in operation.getWarnings()" :key="warning.code">
                    {{ warning.message }}
                </li>
            </ul>
        </BaseAlert>
    </fieldset>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import OperationStatusBadge from './OperationStatusBadge.vue';
import OperationAction from './OperationAction.vue';
import OperationCheckBadge from './OperationCheckBadge.vue';
import CopyToClipboard from '@/components/elements/CopyToClipboard.vue';
import { ActionableOnSuccessOperation, Operation, OperationStatus } from '@/stores/operations/operations';
import { BaseColour } from '@/compiler/types';
import BaseAlert from '@/components/elements/BaseAlert.vue';
import useFormatters from '@/composables/useFormatters';

defineProps({
    operation: {
        type: Object as PropType<Operation>,
        required: true,
    },
});

const { reverseTruncate } = useFormatters();

const isActionable = (operation: Operation): operation is ActionableOnSuccessOperation => {
    return (operation as ActionableOnSuccessOperation).actionIcon !== undefined;
};
</script>
