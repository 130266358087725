import { Currency } from 'dinero.js';
import { AmazonMarketplace } from '../channels';
import itemPrepDetails, { FbaTransportV2024ItemPrepDetail } from './fbaTransportV2024/itemPrepDetails';
import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';
import { SkuError } from '@/composables/useAmazonErrors';

export enum FbaTransportVersions {
    V0 = 'v0',
    V2024 = 'v2024',
}

export enum ShipmentPlan {
    AmazonFbaV2024 = 'Amazon FBA V2024',
    AmazonFbaLegacy = 'Amazon FBA Legacy',
    SelfArranged = 'Self Arranged',
}

export enum FbaTransportV2024ShipmentGroup {
    PartneredSmallParcel = 'PARTNERED_GROUND_SMALL_PARCEL',
    PartneredLTL = 'PARTNERED_FREIGHT_LTL',
    NonPartneredSmallParcel = 'NON_PARTNERED_GROUND_SMALL_PARCEL',
    NonPartneredLTL = 'NON_PARTNERED_FREIGHT_LTL',
}

export enum FbaTransportPlanStatus {
    Active = 'ACTIVE',
    Voided = 'VOIDED',
    Shipped = 'SHIPPED',
}

export interface FbaTransportV2024ShipmentItemPlanInfo {
    channelId: number
    msku: string
    quantity: number
    expiryDate: string
    prepDetails: FbaTransportV2024ItemPrepDetail
    owners: {
        labelOwner: LabelOwner
        prepOwner: PrepOwner
    }
    errors: SkuError[]
}

export interface TransportDimensions {
    lengthMm: number
    widthMm: number
    heightMm: number
    weightGm: number
}

export interface LocaleTransportDimensions {
    length: number
    width: number
    height: number
    weight: number
}

export interface FbaTransportPlan {
    id: string
    createdAt: string
    outboundShipmentId: number
    channelId: number
    name: string
    shipFromAddress: FbaTransportAddress
    boxGroups: FbaTransportBoxGroup[]
    isMarkedAsDeleted: boolean
    items: FbaTransportPlanItemClass[]
}

export interface FbaTransportPlanItem {
    id: string
    quantity: number
    expiration: string | null
}

export interface FbaTransportAddress {
    city: string
    countryCode: string
    postalCode: string
    name: string
    addressLine1: string
    addressLine2?: string
    stateOrProvinceCode: string
    districtOrCounty: string
}

export interface FbaTransportBoxGroup extends TransportDimensions {
    id: string
    boxNumbers: number[]
    items: FbaTransportBoxGroupItem[]
    packingGroupId: string
}

export interface FbaTransportBoxGroupItem {
    id: string
    createdAt: string
    planItemId: string
    quantity: number
}

export interface PalletDetails extends TransportDimensions {
    repeat: number
    stackable: boolean
}

export interface FbaTransportV2024Pallet extends PalletDetails {
    id: string
}

export class FbaTransportPlanItemClass {
    id: string;
    quantity: number;
    sku: string;
    expiration: string | null;
    labelOwner: string;
    prepOwner: string;
    updatedAt: string;

    constructor(
        id: string,
        quantity: number,
        sku: string,
        expiration: string | null,
        labelOwner: string,
        prepOwner: string,
        updatedAt: string,
    ) {
        this.id = id;
        this.quantity = quantity;
        this.sku = sku;
        this.expiration = expiration;
        this.labelOwner = labelOwner;
        this.prepOwner = prepOwner;
        this.updatedAt = updatedAt;
    }
}

export interface FnskuLabel {
    fnsku: string
    title: string
    condition: string
    expiration: string | null
}

export enum LabelOwner {
    Amazon = 'AMAZON',
    Seller = 'SELLER',
    None = 'NONE',
}

export enum PrepOwner {
    Amazon = 'AMAZON',
    Seller = 'SELLER',
    None = 'NONE',
}

export interface FbaTransportV2024Currency {
    amount: number
    code: Currency
}

export interface FbaTransportV2024PrepInstruction {
    fee: FbaTransportV2024Currency
    prepOwner: PrepOwner
    prepType: 'ITEM_LABELING' | 'ITEM_BUBBLEWRAP' | 'ITEM_POLYBAGGING' | 'ITEM_TAPING' | 'ITEM_BLACK_SHRINKWRAP' | 'ITEM_HANG_GARMENT' | 'ITEM_BOXING' | 'ITEM_SETCREAT' | 'ITEM_RMOVHANG' | 'ITEM_SUFFOSTK' | 'ITEM_CAP_SEALING' | 'ITEM_DEBUNDLE' | 'ITEM_SETSTK' | 'ITEM_SIOC' | 'ITEM_NO_PREP' | 'ADULT' | 'BABY' | 'TEXTILE' | 'HANGER' | 'FRAGILE' | 'LIQUID' | 'SHARP' | 'SMALL' | 'PERFORATED' | 'GRANULAR' | 'SET' | 'FC_PROVIDED' | 'UNKNOWN' | 'NONE'
}

export interface FbaTransportV2024CreatePlanItem {
    expiration: string | null
    prepOwner: PrepOwner
    labelOwner: LabelOwner
    msku: string
    quantity: number
    manufacturingLotCode: string | null
}

export interface FbaTransportV2024Incentive {
    description: string
    target: 'Placement Services' | 'Fulfillment Fee Discount'
    type: 'FEE' | 'DISCOUNT'
    value: FbaTransportV2024Currency
}

export interface FbaTransportV2024ShippingConfiguration {
    shippingMode: 'GROUND_SMALL_PARCEL' | 'FREIGHT_LTL' | 'FREIGHT_FTL_PALLET' | 'FREIGHT_FTL_NONPALLET' | 'OCEAN_LCL' | 'OCEAN_FCL' | 'AIR_SMALL_PARCEL' | 'AIR_SMALL_PARCEL_EXPRESS'
    shippingSolution: 'AMAZON_PARTNERED_CARRIER' | 'USE_YOUR_OWN_CARRIER'
}

export interface FbaTransportV2024PackingGroupItem {
    id: string
    planItemId: string
    msku: string
    quantity: number
    labelOwner: LabelOwner
    expiration: string | null
}

export interface FbaTransportV2024PackingGroup {
    id: string
    items: FbaTransportV2024PackingGroupItem[]
}

export interface FbaTransportV2024PackingOption {
    id: string
    discounts: FbaTransportV2024Incentive[]
    expiration: string | null
    fees: FbaTransportV2024Incentive[]
    packingGroupIds: string[]
    packingOptionId: string
    packingGroups: FbaTransportV2024PackingGroup[]
    status: 'OFFERED' | 'ACCEPTED' | 'EXPIRED'
    supportedShippingConfigurations: FbaTransportV2024ShippingConfiguration[]
    confirmedAt: string | null
}

export interface FbaTransportV2024PlanItem extends FbaTransportPlanItem {
    createdAt: string
    updatedAt: string
    msku: string
    labelOwner: LabelOwner
    prepOwner: PrepOwner
    manufacturingLotCode: string | null
}

export interface FbaTransportV2024PlacementOption {
    id: string
    createPlanOperationId: string
    discounts: FbaTransportV2024Incentive[]
    expiration: string | null
    fees: FbaTransportV2024Incentive[]
    shipmentIds: string[]
    shipments: FbaTransportV2024Shipment[]
    placementOptionId: string
    status: 'OFFERED' | 'ACCEPTED' | 'EXPIRED'
    confirmedAt: string | null
    isSelected: boolean
}

export interface FbaTransportV2024DeliveryWindowOption {
    id: string
    createPlanOperationId: string
    deliveryWindowOptionId: string
    shipmentId: string
    availabilityType: string
    startDate: string
    endDate: string
    validUntil: string
    confirmedAt: string | null
}

export interface FbaTransportV2024TransportationOption {
    id: string
    createPlanOperationId: string
    transportationOptionId: string
    shipmentId: string
    carrier: {
        alpha_code: string
        name: string
    }
    carrierAppointment?: string
    shippingMode: string
    shippingSolution: string
    preconditions: string[]
    quote: {
        cost: {
            amount: number
            code: string
        }
    }
    confirmedAt: string | null
}

export interface FbaTransportV2024Plan extends FbaTransportPlan {
    id: string
    createdAt: string
    outboundShipmentId: number
    channelId: number
    name: string
    shipFromAddress: FbaTransportAddress
    marketplace: AmazonMarketplace
    inboundPlanId: string
    isPackingInformationSet: boolean
    createPlanOperationId: string
    packingOptions: FbaTransportV2024PackingOption[]
    boxGroups: FbaTransportBoxGroup[]
    isMarkedAsDeleted: boolean
    placementOptions: FbaTransportV2024PlacementOption[]
    isCanceled: boolean
    using2dBarcodes: boolean
    status: FbaTransportPlanStatus
}

type FbaTransportV2024PlanResponse = Omit<FbaTransportV2024Plan, 'items'> & { items: FbaTransportV2024PlanItem[] };

const mapV2024PlanItemsToClass = (plan: FbaTransportV2024PlanResponse): FbaTransportV2024Plan => {
    const items = plan.items.map(item => new FbaTransportPlanItemClass(item.id, item.quantity, item.msku, item.expiration, item.labelOwner, item.prepOwner, item.updatedAt));

    return Object.assign(plan, { items });
};

export enum FbaTransportV2024OperationStatus {
    Success = 'SUCCESS',
    InProgress = 'IN_PROGRESS',
    Failed = 'FAILED',
}

export interface FbaTransportV2024OperationProblem {
    code: string
    details: string | null
    message: string
    severity: 'WARNING' | 'ERROR'
}

export interface FbaTransportV2024Operation {
    id: string
    createdAt: string
    updatedAt: string
    channelId: number
    operationId: string
    status: FbaTransportV2024OperationStatus
    description: string | null
    problems: FbaTransportV2024OperationProblem[] | null
}

export interface FbaTransportV2024Label {
    id: string
    createdAt: string
    updatedAt: string
    name: string
    url: string
    type: 'box' | 'pallet' | 'package' | 'bill_of_lading'
    paperSize: 'US_Letter' | 'PackageLabel_Letter_2' | 'PackageLabel_A4_4' | '4x6'
}

export enum FbaTransportV2024UnitOfMeasurement {
    CM = 'CM',
    IN = 'IN',
}

export enum FbaTransportV2024UnitOfWeight {
    LB = 'LB',
    KG = 'KG',
}

export enum FbaTransportV2024BoxContentInformationSource {
    BARCODE_2D = 'BARCODE_2D',
    MANUAL_PROCESS = 'MANUAL_PROCESS',
    BOX_CONTENT_PROVIDED = 'BOX_CONTENT_PROVIDED',
}

export enum FbaTransportV2024ShipmentFreightClass {
    NONE = 'NONE',
    FC_50 = 'FC_50',
    FC_55 = 'FC_55',
    FC_60 = 'FC_60',
    FC_65 = 'FC_65',
    FC_70 = 'FC_70',
    FC_77_5 = 'FC_77_5',
    FC_85 = 'FC_85',
    FC_92_5 = 'FC_92_5',
    FC_100 = 'FC_100',
    FC_110 = 'FC_110',
    FC_125 = 'FC_125',
    FC_150 = 'FC_150',
    FC_175 = 'FC_175',
    FC_200 = 'FC_200',
    FC_250 = 'FC_250',
    FC_300 = 'FC_300',
    FC_400 = 'FC_400',
    FC_500 = 'FC_500',
}

export enum FbaTransportV2024ShipmentSourceType {
    SELLER_FACILITY = 'SELLER_FACILITY',
}

export enum FbaTransportV2024ShipmentStatus {
    ABANDONED = 'ABANDONED',
    CANCELLED = 'CANCELLED',
    CHECKED_IN = 'CHECKED_IN',
    CLOSED = 'CLOSED',
    DELETED = 'DELETED',
    DELIVERED = 'DELIVERED',
    IN_TRANSIT = 'IN_TRANSIT',
    MIXED = 'MIXED',
    READY_TO_SHIP = 'READY_TO_SHIP',
    RECEIVING = 'RECEIVING',
    SHIPPED = 'SHIPPED',
    UNCONFIRMED = 'UNCONFIRMED',
    WORKING = 'WORKING',
}

export enum FbaTransportV2024ShipmentDestinationType {
    AMAZON_OPTIMIZED = 'AMAZON_OPTIMIZED',
    AMAZON_WAREHOUSE = 'AMAZON_WAREHOUSE',
}

export interface FbaTransportV2024Weight {
    unit: FbaTransportV2024UnitOfWeight
    value: number
}

export interface FbaTransportV2024Dimensions {
    height: number
    length: number
    width: number
    unitOfMeasurement: FbaTransportV2024UnitOfMeasurement
}

export interface FbaTransportV2024ShipmentContactInformation {
    email: string
    name: string
    phoneNumber: string
}

export interface FbaTransportV2024Address {
    addressLine1: string
    addressLine2: string
    city: string
    companyName: string
    countryCode: string
    email: string
    name: string
    phoneNumber: string
    postalCode: string
    stateOrProvinceCode: string
}

export interface FbaTransportV2024ShipmentDestination {
    address: FbaTransportV2024Address
    destinationType: FbaTransportV2024ShipmentDestinationType
    warehouseId: string
}

export interface FbaTransportV2024ShipmentFreightInformation {
    freightClass: FbaTransportV2024ShipmentFreightClass
    declaredValue: {
        amount: number
        code: string
    }
}

export interface FbaTransportV2024SelectedDeliveryWindow {
    availabilityType: string
    deliveryWindowOptionId: string
    editableUntil: string
    endDate: string
    startDate: string
}

export interface FbaTransportV2024AppointmentSlotTime {
    endDate: string
    startDate: string
}

export interface FbaTransportV2024ShipmentSource {
    address: FbaTransportV2024Address
    sourceType: FbaTransportV2024ShipmentSourceType
}

export interface FbaTransportV2024BoxGroupItem {
    createdAt: string
    id: string
    planItemId: string
    quantity: number
}

export interface FbaTransportV2024BoxGroup {
    id: string
    lengthMm: number
    widthMm: number
    heightMm: number
    weightGm: number
    numberOfBoxes: number
    items: FbaTransportV2024BoxGroupItem[]
    boxNumbers: number[]
}

export interface FbaTransportV2024ShipmentBox {
    id: string
    boxGroupId: string
    amazonBoxId: string
    packageId: string
    boxNumber: number
}

export interface FbaTransportV2024Shipment {
    id?: string
    contactInformation?: FbaTransportV2024ShipmentContactInformation
    firstAvailabilityDate?: string
    transportModePreference?: FbaTransportV2024ShipmentGroup
    destination: FbaTransportV2024ShipmentDestination
    freightInformation?: FbaTransportV2024ShipmentFreightInformation
    selectedDeliveryWindow: FbaTransportV2024SelectedDeliveryWindow
    selectedTransportationOptionId: string
    shipmentConfirmationId?: string
    shipmentId: string
    source?: FbaTransportV2024ShipmentSource
    status?: FbaTransportV2024ShipmentStatus
    boxGroups?: FbaTransportV2024BoxGroup[]
    boxes?: FbaTransportV2024ShipmentBox[]
    pallets?: FbaTransportV2024Pallet[]
    deliveryWindowOptions: FbaTransportV2024DeliveryWindowOption[]
    transportationOptions: FbaTransportV2024TransportationOption[]
    trackingDetails: {
        ltlTrackingDetail: {
            billOfLadingNumber: string
            freightBillNumber: string[]
        }
        spdTrackingDetail: {
            spdTrackingItems: {
                boxId: string
                trackingId: string
                trackingNumberValidationStatus: string
            }[]
        }
    } | null
    amazonReferenceId: string | null
}

const getPlans = async (outboundShipmentId: number): Promise<FbaTransportV2024Plan[]> => {
    const response = await axios.get<{ data: FbaTransportV2024PlanResponse[] }>(`/api/fba-transport/v2024/plans`, {
        params: { outbound_shipment_id: outboundShipmentId.toString() },
    });

    return response.data.data.map(mapV2024PlanItemsToClass);
};

const showPlan = async (planId: string): Promise<FbaTransportV2024Plan> => {
    const response = await axios.get<{ data: FbaTransportV2024PlanResponse }>(`/api/fba-transport/v2024/plans/${planId}`);

    return mapV2024PlanItemsToClass(response.data.data);
};

const generatePackingOptions = async (planId: string): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/generate-packing-options`, {
        plan_id: planId,
    });

    return response.data;
};

const fetchPackingOptions = async (planId: string): Promise<string> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/packing-options`, location.origin);
    url.searchParams.append('plan_id', planId);

    return await axios.get(url.toString());
};

const fetchPackingGroupItems = async (planId: string, packingOptionId: string, packingGroupId: string) => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/packing-group-items`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('packing_option_id', packingOptionId);
    url.searchParams.append('packing_group_id', packingGroupId);

    return await axios.get(url.toString());
};

const confirmPackingOption = async (planId: string, packingOptionId: string) => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/confirm-packing-option`, {
        plan_id: planId,
        packing_option_id: packingOptionId,
    });

    return response.data;
};

const markPackingOptionAsConfirmed = async (planId: string, packingOptionId: string) => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/mark-packing-option-as-confirmed`, {
        plan_id: planId,
        packing_option_id: packingOptionId,
    });

    return response.data;
};

const createPlan = async (
    outboundShipmentId: number,
    channelId: number,
    items: FbaTransportV2024CreatePlanItem[],
): Promise<FbaTransportV2024Plan> => {
    const response = await axios.post<{ data: FbaTransportV2024Plan }>(`/api/fba-transport/v2024/plans`, {
        outboundShipmentId,
        channelId,
        items,
    });

    return response.data.data;
};

const updatePlan = async (planId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/update`);
};

const getOperations = fetchPaginatedDataFunction<FbaTransportV2024Operation>('/api/fba-transport/v2024/operations');

const showOperation = async (operationId: string): Promise<FbaTransportV2024Operation> => {
    const response = await axios.get<{ data: FbaTransportV2024Operation }>(`/api/fba-transport/v2024/operations/${operationId}`);

    return response.data.data;
};

const fetchOperationUpdate = async (operationId: string): Promise<FbaTransportV2024Operation> => {
    const response = await axios.get<{ data: FbaTransportV2024Operation }>(`/api/fba-transport/v2024/operations/${operationId}/update`);

    return response.data.data;
};

const generatePlacementOptions = async (planId: string) => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/generate-placement-options`, {
        plan_id: planId,
    });

    return response.data;
};

const fetchPlacementOptions = async (planId: string): Promise<string> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/placement-options`, location.origin);
    url.searchParams.append('plan_id', planId);

    return await axios.get(url.toString());
};

const resetPlacementOptions = async (planId: string): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/reset-placement-options`);
    return response.data;
};

const generateDeliveryWindowOptions = async (planId: string, shipmentId: string): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/generate-delivery-window-options`, {
        plan_id: planId,
        shipment_id: shipmentId,
    });

    return response.data;
};

const fetchDeliveryWindowOptions = async (planId: string, placementOptionId: string, shipmentId: string): Promise<string> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/delivery-window-options`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);
    url.searchParams.append('shipment_id', shipmentId);

    return await axios.get(url.toString());
};

const generateTransportationOptions = async (planId: string, placementOptionId: string, shipments: { shipmentId: string, startDate: string }[]) => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/generate-transportation-options`, {
        plan_id: planId,
        placement_option_id: placementOptionId,
        shipments_dates: shipments,
    });

    return response.data;
};

const fetchTransportationOptions = async (planId: string, placementOptionId: string, shipmentId: string) => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/transportation-options`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);
    url.searchParams.append('shipment_id', shipmentId);

    return await axios.get(url.toString());
};

const createBoxGroup = async (planId: string, packingGroupId: string, dimensions: TransportDimensions): Promise<string> => {
    const response = await axios.post<{ boxGroupId: string }>(`/api/fba-transport/v2024/plans/${planId}/box-groups`, {
        ...dimensions,
        packingGroupId,
        version: 'v2024',
    });

    return response.data.boxGroupId;
};

const updateBoxGroup = async (planId: string, boxGroupId: string, dimensions: TransportDimensions): Promise<void> => {
    return axios.put(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}`, {
        ...dimensions,
        version: 'v2024',
    });
};

const removeBoxGroup = async (planId: string, boxGroupId: string): Promise<void> => {
    return axios.delete(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}`, {
        data: { version: 'v2024' },
    });
};

const setBoxQuantityForBoxGroup = async (planId: string, boxGroupId: string, quantity: number): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}/set-box-quantity`, {
        quantity,
        version: 'v2024',
    });
};

const createBoxGroupItem = async (
    planId: string,
    boxGroupId: string,
    item: Omit<FbaTransportBoxGroupItem, 'id'>,
): Promise<FbaTransportBoxGroupItem> => {
    const response = await axios.post<{ boxGroupItem: FbaTransportBoxGroupItem }>(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}/items`, {
        ...item,
        version: 'v2024',
    });

    return response.data.boxGroupItem;
};

const updateBoxGroupItem = async (
    planId: string,
    boxGroupId: string,
    itemId: string,
    quantity: number,
): Promise<FbaTransportBoxGroupItem> => {
    const response = await axios.put<{ boxGroupItem: FbaTransportBoxGroupItem }>(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}/items/${itemId}`, {
        quantity,
        version: 'v2024',
    });

    return response.data.boxGroupItem;
};

const removeBoxGroupItem = async (planId: string, boxGroupId: string, itemId: string): Promise<void> => {
    return axios.delete(`/api/fba-transport/v2024/plans/${planId}/box-groups/${boxGroupId}/items/${itemId}`, {
        data: { version: 'v2024' },
    });
};

const createPallet = async (planId: string, placementOptionId: string, shipmentId: string, details: PalletDetails): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/pallets`, {
        placementOptionId,
        shipmentId,
        ...details,
    });
};

const updatePallet = async (planId: string, placementOptionId: string, shipmentId: string, palletId: string, details: PalletDetails): Promise<void> => {
    return axios.put(`/api/fba-transport/v2024/plans/${planId}/pallets/${palletId}`, {
        placementOptionId,
        shipmentId,
        ...details,
    });
};

const removePallet = async (planId: string, placementOptionId: string, shipmentId: string, palletId: string): Promise<void> => {
    return axios.delete(`/api/fba-transport/v2024/plans/${planId}/pallets/${palletId}`, {
        data: {
            placementOptionId,
            shipmentId,
        },
    });
};

const setNonPartneredLtlTracking = async (
    planId: string,
    shipmentId: string,
    billOfLadingNumber: string,
    freightBillNumber: string[],
): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/update-tracking-ltl-details`, {
        planId,
        shipmentId,
        billOfLadingNumber,
        freightBillNumber,
    });

    return response.data;
};

const setNonPartneredSmallParcelTracking = async (
    planId: string,
    shipmentId: string,
    boxTrackingIds: { amazonBoxId: string, trackingId: string }[],
): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/update-tracking-small-details`, {
        planId,
        shipmentId,
        boxTrackingIds,
    });
    return response.data;
};

const updateContactInformation = async (planId: string, placementOptionId: string, shipmentId: string, details: FbaTransportV2024ShipmentContactInformation): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/update-contact-information`, {
        planId,
        placementOptionId,
        shipmentId,
        ...details,
    });
};

const updateFreightInformation = async (
    planId: string,
    placementOptionId: string,
    shipmentId: string,
    details: {
        freightClass: FbaTransportV2024ShipmentFreightClass
        amount?: string
        code?: string
    },
): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/update-freight-information`, {
        planId,
        placementOptionId,
        shipmentId,
        ...details,
    });
};

const setFirstAvailabilityDate = async (planId: string, placementOptionId: string, shipmentId: string, firstAvailabilityDate: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/set-first-availability-date`, {
        planId,
        placementOptionId,
        shipmentId,
        firstAvailabilityDate,
    });
};

const setTransportModePreference = async (planId: string, placementOptionId: string, shipmentId: string, transportModePreference: FbaTransportV2024ShipmentGroup): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/set-transport-mode-preference`, {
        planId,
        placementOptionId,
        shipmentId,
        transportModePreference,
    });
};

const getLabels = async (planId: string, shipmentId: string): Promise<FbaTransportV2024Label[]> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/labels`, location.origin);
    url.searchParams.append('shipment_id', shipmentId);

    const response = await axios.get(url.toString());
    return response.data.data;
};

const requestFnskuLabels = async (labels: FnskuLabel[]): Promise<void> => {
    return await axios.post(`/api/fba-transport/request-fnsku-labels`, { labels });
};

const requestPackageLabels = async (planId: string, shipmentId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/request-package-labels`, { shipmentId });
};

const requestBoxLabels = async (planId: string, shipmentId: string, boxGroupId: string | null = null): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/request-box-labels`, { shipmentId, boxGroupId });
};

const requestPalletLabels = async (planId: string, shipmentId: string, pallets: { number: number }[]): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/request-pallet-labels`, { shipmentId, pallets });
};

const downloadPalletLabels = async (planId: string, shipmentId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/download-pallet-labels`, { shipmentId });
};

const requestBillOfLading = async (planId: string, shipmentId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/request-bill-of-lading`, { shipmentId });
};

const resizeToFourBySix = async (planId: string, shipmentId: string, labelId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/labels/${labelId}/resize-to-4x6`, { shipmentId });
};

const changeUsing2dBarcodes = async (planId: string, using2dBarcodes: boolean): Promise<void> => {
    return axios.post(`/api/fba-transport/v2024/plans/${planId}/change-using-2d-barcodes`, { using2dBarcodes });
};

const setPackingInformation = async (planId: string) => {
    const response = axios.post(`/api/fba-transport/v2024/plans/${planId}/set-packing-information`, {
        planId,
    });

    return (await response).data;
};

const markPackingInformationAsConfirmed = async (planId: string) => {
    const response = axios.post(`/api/fba-transport/v2024/plans/${planId}/add-carton-request-to-submission`, {
        planId,
    });

    return (await response).data;
};

const resetSubmissions = async (planId: string): Promise<string> => {
    const response = await axios.post(`/api/fba-transport/v2024/plans/${planId}/reset-submissions`);
    return response.data;
};

const selectPlacementOption = async (planId: string, placementOptionId: string): Promise<string> => {
    return await axios.post(`/api/fba-transport/v2024/plans/${planId}/select-placement-option`, {
        planId,
        placementOptionId,
    });
};

const confirmPlacementOption = async (planId: string, placementOptionId: string): Promise<string> => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/confirm-placement-option`, {
        planId,
        placementOptionId,
    });
    return data;
};

const markPlacementOptionAsConfirmed = async (planId: string, placementOptionId: string) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/mark-placement-option-as-confirmed`, {
        planId,
        placementOptionId,
    });
    return data;
};

const getPlanBoxes = async (planId: string): Promise<void> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/set-boxes-ids`, location.origin);
    return await axios.get(url.toString());
};

const confirmDeliveryWindowOption = async (planId: string, placementOptionId: string, shipmentId: string, deliveryWindowOptionId: string): Promise<string> => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/confirm-delivery-window-option`, {
        planId,
        placementOptionId,
        shipmentId,
        deliveryWindowOptionId,
    });
    return data;
};

const markDeliveryWindowOptionAsConfirmed = async (planId: string, shipmentId: string, deliveryWindowOptionId: string) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/mark-delivery-window-option-as-confirmed`, {
        planId,
        shipmentId,
        deliveryWindowOptionId,
    });
    return data;
};

const confirmTransportationOption = async (planId: string, placementOptionId: string, shipments: { shipmentId: string, transportationOptionId: string }[]): Promise<string> => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/confirm-transportation-option`, {
        plan_id: planId,
        placement_option_id: placementOptionId,
        shipment_transportation_ids: shipments,
    });
    return data;
};

const markTransportationOptionsAsConfirmed = async (planId: string, shipments: { shipmentId: string, transportationOptionId: string }[]) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/mark-transportation-option-as-confirmed`, {
        planId,
        shipment_transportation_ids: shipments,
    });
    return data;
};

const getShipments = async (planId: string, placementOptionId: string): Promise<FbaTransportV2024Shipment[]> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/get-shipment`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);

    const response = await axios.get<{ data: FbaTransportV2024Shipment[] }>(url.toString());

    return response.data.data;
};

const fetchShipmentBoxGroups = async (planId: string, placementOptionId: string, shipmentId: string): Promise<FbaTransportV2024BoxGroup[]> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/list-shipment-boxes`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);
    url.searchParams.append('shipment_id', shipmentId);

    const response = await axios.get<{ data: FbaTransportV2024BoxGroup[] }>(url.toString());

    return response.data.data;
};

const cancelPlanChecker = async (planId: string) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/cancel-plan-checker`, {
        planId,
    });
    return data;
};

const cancelPlan = async (planId: string) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/cancel-plan`, {
        planId,
    });
    return data;
};

const markPlanAsCanceled = async (planId: string) => {
    const { data } = await axios.post(`/api/fba-transport/v2024/plans/${planId}/mark-plan-as-canceled`, {
        planId,
    });
    return data;
};

const getPickListUrl = async (planId: string, placementOptionId: string, shipmentId: string): Promise<string> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/pick-list`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);
    url.searchParams.append('shipment_id', shipmentId);

    const res = await axios.get(url.toString());

    return res.data.url;
};

const getPackListUrl = async (planId: string, placementOptionId: string, shipmentId: string): Promise<string> => {
    const url = new URL(`/api/fba-transport/v2024/plans/${planId}/pack-list`, location.origin);
    url.searchParams.append('plan_id', planId);
    url.searchParams.append('placement_option_id', placementOptionId);
    url.searchParams.append('shipment_id', shipmentId);

    const res = await axios.get(url.toString());

    return res.data.url;
};

export default {
    getPlans,
    showPlan,
    createPlan,
    updatePlan,
    getOperations,
    showOperation,
    fetchOperationUpdate,
    generatePackingOptions,
    fetchPackingOptions,
    fetchPackingGroupItems,
    confirmPackingOption,
    markPackingOptionAsConfirmed,
    generatePlacementOptions,
    fetchPlacementOptions,
    resetPlacementOptions,
    generateDeliveryWindowOptions,
    fetchDeliveryWindowOptions,
    generateTransportationOptions,
    fetchTransportationOptions,
    createBoxGroup,
    updateBoxGroup,
    removeBoxGroup,
    setBoxQuantityForBoxGroup,
    createBoxGroupItem,
    updateBoxGroupItem,
    removeBoxGroupItem,
    createPallet,
    updatePallet,
    removePallet,
    setNonPartneredLtlTracking,
    setNonPartneredSmallParcelTracking,
    updateContactInformation,
    updateFreightInformation,
    setFirstAvailabilityDate,
    setTransportModePreference,
    changeUsing2dBarcodes,
    setPackingInformation,
    markPackingInformationAsConfirmed,
    resetSubmissions,
    getLabels,
    requestFnskuLabels,
    requestPackageLabels,
    requestBoxLabels,
    requestPalletLabels,
    downloadPalletLabels,
    requestBillOfLading,
    resizeToFourBySix,
    selectPlacementOption,
    confirmPlacementOption,
    markPlacementOptionAsConfirmed,
    getPlanBoxes,
    confirmDeliveryWindowOption,
    markDeliveryWindowOptionAsConfirmed,
    confirmTransportationOption,
    markTransportationOptionsAsConfirmed,
    getShipments,
    fetchShipmentBoxGroups,

    itemPrepDetails,
    cancelPlanChecker,
    cancelPlan,
    markPlanAsCanceled,
    getPickListUrl,
    getPackListUrl,
};
